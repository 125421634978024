<template>
  <div>
    <b-row>
      <b-col>
        <b-nav
          card-header
          pills
          class="m-0"
        >
          <b-nav-item
            :to="{ name: 'input-inventory-stock' }"
            exact
            exact-active-class="sub-tab-nav"
            :link-classes="['sub-tab-nav','px-3', bgTabsNavs]"
          >Input</b-nav-item>
          <b-nav-item
            :to="{ name: 'output-inventory-history' }"
            exact
            exact-active-class="sub-tab-nav"
            :link-classes="['sub-tab-nav','px-3', bgTabsNavs]"
          >Output</b-nav-item>
        </b-nav>
      </b-col>
    </b-row>

    <b-card
      no-body
      class="border-top-info border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'NombreDelComponente',
  // Propiedades, métodos y otros componentes aquí
};
</script>

<style scoped>
    /* Estilos específicos del componente aquí */
</style>
